<template>
  <v-card>
    <v-card-title class="transparent-2">
      {{
        customersList.length > 1
          ? `Quoting API's ${customersList.length}`
          : `Quoting API ${customersList.length}`
      }}

      <v-spacer></v-spacer>
      <v-btn @click="refresh()" :color="'primary'" icon>
        <v-icon> mdi-refresh </v-icon>
      </v-btn>
      <v-btn @click="createCustomerDialogShow()" :color="'success'" icon>
        <v-icon> mdi-pencil </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-row class="mt-4">
        <v-col v-for="(user, index) in customersList" :key="index" cols="6">
          <v-card
            @click="createCustomerDialogShow(user)"
            shaped
            class="fill-height"
            elevation="1"
          >
            <v-card-title class="transparent-1 pa-1 px-4">
              <div>
                {{ user.name }}
                <small> ({{ user.customer.payload.business_name }}) </small>
              </div>
              <v-spacer></v-spacer>
              <div>
                <v-btn @click.stop="deleteCustomer(user)" :color="'error'" icon>
                  <v-icon> mdi-delete </v-icon>
                </v-btn>

                <v-btn
                  v-if="user.customer.payload.is_new"
                  @click.stop="toggleIsNewCustomer(user)"
                  :color="'success'"
                  icon
                >
                  <v-icon> mdi-circle </v-icon>
                </v-btn>

                <v-btn
                  @click.stop="customerDetailsDialogShow(user)"
                  :color="'primary'"
                  icon
                >
                  <v-icon> mdi-view-list-outline </v-icon>
                </v-btn>
              </div>
            </v-card-title>
            <v-card-text class="mt-4">
              <!-- New Request:
										{{ user.customer.payload.is_new ? "Yes" : "No" }}
										<br /> -->
              Account Active: {{ user.is_active ? "Yes" : "No" }}
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <v-dialog
      @click:outside="createCustomerDialogClose()"
      v-model="createCustomerDialog"
      v-if="createCustomerDialog"
      max-width="700px"
      persistent
    >
      <v-card>
        <registerCustomer
          @close-dialog="createCustomerDialogClose()"
          @registered="refresh(), createCustomerDialogClose()"
          @refresh="refresh()"
          :selectedModel="selectedCustomer"
          :is_request="false"
          :is_page="false"
          v-if="createCustomerDialog"
        />
      </v-card>
    </v-dialog>
    <customerDetailsDialog
      v-if="customerDetailsDialog"
      :dialogModel="customerDetailsDialog"
      :selectedModel="selectedCustomer"
      @closeDialog="customerDetailsDialog = false"
      @created="customerDetailsDialog = false"
      @refresh="refresh()"
    />
  </v-card>
</template>

<script>
export default {
  components: {
    customerDetailsDialog: () =>
      import("@/components/dialogs/customerDetailsDialog"),
    registerCustomer: () => import("@/components/forms/registerCustomer"),
  },
  data: () => ({
    customersList: [],
    createCustomerDialog: false,
    customerDetailsDialog: false,
    selectedCustomer: null,
  }),
  mounted() {
    this.getCustomerList();
  },
  computed: {},
  methods: {
    customerDetailsDialogClose() {
      this.customerDetailsDialog = false;
      this.selectedCustomer = null;
    },
    customerDetailsDialogShow(user) {
      this.customerDetailsDialog = true;
      this.selectedCustomer = user;
    },
    createCustomerDialogClose() {
      this.createCustomerDialog = false;
      this.selectedCustomer = null;
    },
    createCustomerDialogShow() {
      this.createCustomerDialog = true;
      this.selectedCustomer = null;
    },
    async refresh() {
      await this.getCustomerList();
      await this.$store.dispatch("auth/getUserNotifications");
      if (this.customerDetailsDialog && this.selectedCustomer) {
        let customer = this.customersList.find(
          (customer) => this.selectedCustomer.id == customer.id
        );
        this.customerDetailsDialogClose();
        this.customerDetailsDialogShow(customer);
      }
    },
    async getCustomerList() {
      return await this.post(
        `${this.baseUrl}/get-customer-list`,
        {},
        true
      ).then((data) => {
        if (data == "undefined") return;
        // this.showMessageFromResponseDta(data);

        if (data.code == 200) {
          this.customersList = data.data;
        }
      });
    },
    async toggleIsNewCustomer(user) {
      return await this.post(
        `${this.baseUrl}/toggle-is-new-customer`,
        { user_id: user.id },
        true
      ).then((data) => {
        if (data == "undefined") return;
        this.showMessageFromResponseDta(data);

        if (data.code == 200) {
          this.refresh();
        }
      });
    },
    async deleteCustomer(user) {
      if (!confirm("All Data related to this user will be deleted."))
        return true;
      if (!confirm("Are you sure you want to continue?")) return true;

      return await this.post(
        `${this.baseUrl}/delete-customer`,
        { user_id: user.id },
        true
      ).then((data) => {
        if (data == "undefined") return;
        this.showMessageFromResponseDta(data);

        if (data.code == 200) {
          this.refresh();
        }
      });
    },
  },
};
</script>
